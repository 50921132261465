import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { getCategoryColor } from '../components/context/AppContext';
import { BenPage } from '../components/pagelayout/ben/ben';

export const pageQuery = graphql`
  query BenPageQuery {
    ben: wpPage(slug: { eq: "game-1-ben-illustrations-challenges-index" }) {
      title
      gameOne {
        gameTitle
        illustrations {
          artistName
          description
          audioAlternative {
            localFile {
              publicURL
            }
          }
          imageThumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tag {
            slug
            name
          }
        }
      }
    }
  }
`;

const Ben = ({ data }) => {
  const { ben } = data;
  const illustrations = ben?.gameOne?.illustrations;
  const tags = illustrations
    .flatMap(el => el.tag.map(eltag => eltag))
    .filter((s1, pos, arr) => arr.findIndex(s2 => s2.slug === s1.slug) === pos)
    .sort((a, b) => a.slug.localeCompare(b.slug));

  return (
    <>
      <SEO title="Ben" />
      <Helmet
        htmlAttributes={{
          class: getCategoryColor('plain'),
        }}
        bodyAttributes={{
          class: getCategoryColor('plain'),
        }}
        // link={{
        //   href: 'https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap',
        //   rel: 'stylesheet',
        // }}
      >
        <link href="https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap" rel="stylesheet" />
      </Helmet>
      <BenPage ben={ben} illustrations={illustrations} tags={tags} />
    </>
  );
};

export default Ben;
