import React, { useState } from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { Icon } from '../../common/Icon/Icon';
import styles from './Ben.module.scss';
import { BenOverlay } from './benOverlay';

const BenPage = ({ ben, illustrations, tags }) => {
  const [overlay, setOverlay] = useState(false);
  const [currentIllustrations, setCurrentIllustrations] = useState(illustrations);
  const [currentArtwork, setCurrentArtwork] = useState(0);
  const [currentTag, setCurrentTag] = useState('all');
  const [toggleFilters, setToggleFilters] = useState(false);

  const selectArtwork = index => {
    if (currentIllustrations.length > 0) setOverlay(true);
    setCurrentArtwork(index);
  };

  const selectIllustrations = slug => {
    setCurrentTag(slug);
    const illustrationsToFilter = illustrations;
    const filteredIllustrations =
      slug === 'all'
        ? illustrationsToFilter
        : illustrationsToFilter.filter(illustration => illustration.tag.some(tag => tag.slug === slug));
    setCurrentIllustrations(filteredIllustrations);
  };

  return (
    <>
      <div className={styles.benWrapper}>
        <div className={styles.gallery} key={currentTag}>
          <h1>A World That Is / A World That Isn’t</h1>
          <button
            onClick={() => setToggleFilters(!toggleFilters)}
            className={classNames(styles.filterToggle, styles.button)}
          >
            <Icon size="free" name="arrow-drawn" /> filters
          </button>
          <div className={styles.imageGrid}>
            {currentIllustrations &&
              currentIllustrations.map((illustration, index) => (
                <div key={index} onClick={() => selectArtwork(index)} className={styles.galleryItem}>
                  <Img
                    className={styles.audioImage}
                    fluid={illustration.imageThumbnail?.localFile?.childImageSharp?.fluid}
                    alt={illustration.artistName}
                  />
                </div>
              ))}
          </div>
        </div>
        <div
          className={classNames(styles.filtersCloser, { [styles.active]: toggleFilters })}
          onClick={() => setToggleFilters(false)}
        ></div>
        <div className={classNames(styles.filters, { [styles.active]: toggleFilters })}>
          <div className={styles.filtersInner}>
            <div className={styles.filtersClose} onClick={() => setToggleFilters(false)}>
              <Icon size="free" name="x" className={styles.filterX} /> Close
            </div>
            <h2>Choose a filter</h2>
            <ul>
              <li className={classNames({ [styles.active]: currentTag === 'all' })}>
                <button
                  onClick={() => selectIllustrations('all')}
                  className={classNames(styles.button, styles.allButton)}
                >
                  {currentTag !== 'all' && <Icon size="free" name="x" className={styles.filterX} />}
                  View All
                </button>
              </li>
              {tags &&
                tags.map((tag, index) => (
                  <li key={index} className={classNames({ [styles.active]: tag.slug === currentTag })}>
                    <button onClick={() => selectIllustrations(tag.slug)} className={styles.button}>
                      {tag.name}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <BenOverlay
        active={overlay}
        onClose={() => setOverlay(false)}
        slides={currentIllustrations}
        startSlide={currentArtwork}
      />
      {/* <BreakOverlay active={breakOverlay} onClose={() => setBreakOverlay(false)} /> */}
    </>
  );
};

export { BenPage };
