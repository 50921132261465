import React, { useCallback, useRef, useState } from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { Overlay } from '../../common/Overlay';
// import { Wrapper } from '../Wrapper';
import { Slider } from '../../common/Slider';
import { Icon } from '../../common/Icon/Icon';
import { HubAudioPlayer } from '../../common/HubAudioPlayer';

import styles from './BenOverlay.module.scss';

const BenOverlay = ({ active, onClose, slides, startSlide }) => {
  const sliderRef = useRef(null);
  const [closeHover, setCloseHover] = useState(false);

  const onRef = useCallback(ref => {
    if (ref) {
      sliderRef.current = ref;
    }
  }, []);

  const onPrev = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('prev');
  }, []);

  const onNext = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('next');
  }, []);

  return (
    <Overlay lockScroll={true} backdrop={false} active={active} onClose={onClose}>
      <div className={styles.close}>
        <button
          onClick={onClose}
          className={styles.closeButton}
          onMouseEnter={() => setCloseHover(true)}
          onMouseLeave={() => setCloseHover(false)}
        >
          {closeHover ? <Icon name="ben-close-active" size="free" /> : <Icon name="ben-close" size="free" />}
        </button>
        {/* <Overlay.Close onClick={onClose} /> */}
      </div>
      <div>
        <Slider
          className={styles.wrapper}
          onRef={onRef}
          settings={{
            controls: false,
            nav: false,
            mouseDrag: false,
            edgePadding: 16,
            gutter: 100,
            center: true,
            startIndex: startSlide,
          }}
        >
          {slides &&
            slides.map((slide, index) => (
              <div key={index} className={styles.content}>
                <div className={styles.contentContainer}>
                  <div className={styles.imageContainer}>
                    <Img
                      fluid={slide.image?.localFile?.childImageSharp?.fluid}
                      objectFit="contain"
                      objectPosition="50% 50%"
                      imgStyle={{
                        objectFit: 'contain',
                        objectPosition: '50% 50%',
                      }}
                      alt={slide.artistName}
                      className={styles.gatsbyImage}
                    />
                  </div>
                  <div className={styles.slideInfo}>
                    <div className={styles.slideDescription}>{slide.description}</div>
                    {/* <div className={styles.slideAudio}>{slide.audioAlternative?.localFile?.publicURL}</div> */}

                    <div className={styles.slideArtistName}>By {slide.artistName}</div>
                    <HubAudioPlayer
                      src={slide.audioAlternative?.localFile?.publicURL}
                      label="Listen"
                      className={styles.audioPlayer}
                    />
                  </div>
                </div>
              </div>
            ))}
        </Slider>
        <div className={styles.controls}>
          <button className={classNames(styles.control, styles.controlPrev)} onClick={onPrev} aria-label="next">
            <Icon size="free" name="arrow-drawn" mirror />
          </button>
          <button className={classNames(styles.control, styles.controlNext)} onClick={onNext} aria-label="next">
            <Icon size="free" name="arrow-drawn" />
          </button>
        </div>
      </div>
      {/* </Wrapper> */}
    </Overlay>
  );
};

export { BenOverlay };
